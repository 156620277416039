import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Button } from './button'
import { ContentfulComponentPartnerLink } from 'types/contentful'
import * as styles from './section-partner-links.module.css'
import { servePartnerLinkWithSignUpToken } from 'lib/signup-form-helpers'

type SectionPartnerLinksProps = {
  heading?: string
  partnerLinks: ContentfulComponentPartnerLink[]
  index: number
  signUpToken?: string
}

const SectionPartnerLinks = ({
  heading,
  partnerLinks,
}: SectionPartnerLinksProps) => {
  const [signUpToken, setSignUpToken] = useState<string>('')

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setSignUpToken(urlParams.get('signUpToken') ?? '')
  }, [])

  return (
    <div className="edge-container">
      {heading && <h2 className={styles.heading}>{heading}</h2>}
      <div className={styles.partnerLinks}>
        {(partnerLinks || []).map((partnerLink) => (
          <div key={partnerLink.id} className={styles.partnerLink}>
            <div className={styles.partnerLinkSection}>
              {partnerLink.logoImage && (
                <div className={styles.imageWrapper}>
                  <GatsbyImage
                    alt={partnerLink.logoImage.title || 'Logo'}
                    image={partnerLink.logoImage.gatsbyImageData}
                  />
                </div>
              )}
              {partnerLink.deviceImage && (
                <div className={styles.imageWrapper}>
                  <GatsbyImage
                    alt={partnerLink.deviceImage.title || 'Name'}
                    image={partnerLink.deviceImage.gatsbyImageData}
                    objectFit="cover"
                    objectPosition="center"
                  />
                </div>
              )}
            </div>
            <div className={styles.partnerLinkSection}>
              {partnerLink.buttonOneUrl && (
                <div className={styles.buttonWrapper}>
                  <Button
                    text={partnerLink.buttonOneText}
                    url={servePartnerLinkWithSignUpToken(
                      partnerLink.buttonOneUrl,
                      signUpToken
                    )}
                    variant="primary"
                  />
                </div>
              )}
              {partnerLink.buttonTwoUrl && (
                <div className={styles.buttonWrapper}>
                  <Button
                    text={partnerLink.buttonTwoText}
                    url={partnerLink.buttonTwoUrl}
                    variant="secondary"
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SectionPartnerLinks

export const query = graphql`
  fragment ContentfulSectionPartnerLinksFields on ContentfulSectionPartnerLinks {
    id
    heading
    partnerLinks {
      id
      logoImage {
        gatsbyImageData(layout: CONSTRAINED, height: 50)
        title
      }
      deviceImage {
        gatsbyImageData(layout: CONSTRAINED, height: 120)
        title
      }
      buttonOneText
      buttonOneUrl
      buttonTwoText
      buttonTwoUrl
    }
  }
`
